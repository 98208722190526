import React, { FC } from 'react';

import { Agent } from './Agent';
import { Integrations } from './Integrations';
import { ForwardMetrics } from './ForwardMetrics';
import { SyntheticMonitoring } from './SyntheticMonitoring';
import { Datasources } from './Datasources';

import { useColors } from '../../hooks';
import { IconTypes } from './types';
import { K6App } from './K6App';

interface IconProps {
  logo: string;
}

export const Icon: FC<IconProps> = ({ logo }) => {
  const { iconColor } = useColors();

  switch (logo) {
    case IconTypes.AGENT:
      return <Agent iconColor={iconColor} />;
    case IconTypes.INTEGRATIONS:
      return <Integrations iconColor={iconColor} />;
    case IconTypes.SYNTHETICMONITORING:
      return <SyntheticMonitoring />;
    case IconTypes.FORWARDMETRICS:
      return <ForwardMetrics iconColor={iconColor} />;
    case IconTypes.DATASOURCES:
      return <Datasources iconColor={iconColor} />;
    case IconTypes.K6:
      return <K6App iconColor={iconColor} />;
    default:
      return null;
  }
};
