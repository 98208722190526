export interface IconProps {
  iconColor?: string;
}

export enum IconTypes {
  AGENT = 'agent',
  INTEGRATIONS = 'integrations',
  SYNTHETICMONITORING = 'synthetic-monitoring',
  FORWARDMETRICS = 'forwardMetrics',
  DATASOURCES = 'datasources',
  K6 = 'k6-app',
}
