import React, { FC } from 'react';
import { css } from 'emotion';

import { GrafanaTheme } from '@grafana/data';
import { useStyles } from '@grafana/ui';
import { colors } from '../../utils';
import { Icon } from '../Icons';

export interface CardButtonProps {
  href: string;
  logo: string;
  title: string;
}

const getStyles = (theme: GrafanaTheme) => {
  return {
    card: css`
      display: flex;
      flex: 1;
      background: ${theme.colors.bg2};
      padding: 16px;
      border-radius: 2px;
      margin-bottom: 8px;
      &:hover {
        background: ${theme.colors.bg3};
      }
    `,
    header: css`
      color: ${theme.isLight ? theme.palette.gray33 : colors.darkText};
      font-size: 19px;
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 0;
      line-height: 24px;
      @media (max-width: 1500px) {
        font-size: 15px;
        line-height: 17px;
      }
    `,
    headerContent: css`
      p {
        margin-bottom: 0;
        font-size: ${theme.typography.size.sm};
      }
      @media (max-width: 650px) {
        display: none;
      }
    `,
    logoWrapper: css`
      display: flex;
      justify-content: center;
      margin-right: 10px;
    `,
    titleWrapper: css`
      display: flex;
      align-items: center;
    `,
  };
};

export const CardButton: FC<CardButtonProps> = ({ href, logo, title }) => {
  const styles = useStyles(getStyles);

  return (
    <a className={styles.card} href={href} tabIndex={-1}>
      <div className={styles.titleWrapper}>
        <div className={styles.logoWrapper}>
          <Icon logo={logo} />
        </div>
        <div className={styles.headerContent}>
          <div className={styles.header}>{title}</div>
        </div>
      </div>
    </a>
  );
};
