import { useTheme } from '@grafana/ui';
import { colors } from '../utils';

export const useColors = () => {
  const theme = useTheme();
  const { darkIcon, lightIcon } = colors;
  const iconColor = theme.isDark ? darkIcon : lightIcon;

  return { iconColor };
};
