import { css, cx } from 'emotion';
import React from 'react';

import { PanelProps } from '@grafana/data';

import { SimpleOptions } from 'types';
import { PANEL_BREAKPOINT } from '../../utils';
import { CardButton } from '../CardButton';
import { IconTypes } from '../Icons';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    list: css`
      display: flex;
      flex-direction: row;
      list-style: none;
      flex-grow: 1;
    `,
    item: css`
      display: flex;
      flex: 1 1 0px;
      margin: 0px 8px;
      @media (max-width: 650px) {
        margin: 0px 4px;
      }
      & > div > a > div {
        flex-wrap: unset;
      }
    `,
  };
};

export const CloudHomeDiscover: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = getStyles();

  return (
    <div
      className={cx(
        'discover-panel',
        css`
          width: ${width}px;
          height: ${height}px;
          display: flex;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        `
      )}
    >
      <ul className={styles.list}>
        <li tabIndex={-1} className={styles.item}>
          <CardButton
            title={`${width > PANEL_BREAKPOINT ? 'Install agent integrations' : 'Agent'}`}
            href="/a/grafana-easystart-app?cat=integration"
            logo={IconTypes.AGENT}
          />
        </li>
        <li tabIndex={-1} className={styles.item}>
          <CardButton
            title={`${width > PANEL_BREAKPOINT ? 'Forward metrics, logs, traces' : 'Forward metrics'}`}
            href="/a/grafana-easystart-app?cat=hosted-data"
            logo={IconTypes.FORWARDMETRICS}
          />
        </li>
        <li tabIndex={-1} className={styles.item}>
          <CardButton
            title={`${width > PANEL_BREAKPOINT ? 'Query external sources' : 'Query sources'}`}
            href="/datasources"
            logo={IconTypes.DATASOURCES}
          />
        </li>
        <li tabIndex={-1} className={styles.item}>
          <CardButton
            title={`${width > PANEL_BREAKPOINT ? 'Set up Synthetic Monitoring' : 'Synthetic Monitoring'}`}
            href="/a/grafana-synthetic-monitoring-app/"
            logo={IconTypes.SYNTHETICMONITORING}
          />
        </li>
        <li tabIndex={-1} className={styles.item}>
          <CardButton title={'Performance testing'} href="/a/k6-app/" logo={IconTypes.K6} />
        </li>
      </ul>
    </div>
  );
};
