import React from 'react';

import { IconProps } from './types';

export const Integrations = ({ iconColor }: IconProps) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9232 11.1141C22.8531 10.9554 22.7392 10.8193 22.5945 10.721C22.4497 10.6228 22.28 10.5664 22.1043 10.5583H17.7936L18.9889 6.16767C19.027 6.03028 19.0324 5.8861 19.0046 5.74633C18.9769 5.60657 18.9167 5.47498 18.8289 5.3618C18.7412 5.2497 18.6286 5.15886 18.4997 5.09624C18.3708 5.03362 18.2291 5.00088 18.0853 5.00054H11.4968C11.2843 4.99338 11.0756 5.05723 10.9047 5.1817C10.7338 5.30617 10.6108 5.48392 10.5556 5.686L8.03316 14.9489C7.99509 15.0863 7.98972 15.2305 8.01747 15.3703C8.04522 15.51 8.10534 15.6416 8.19316 15.7548C8.28181 15.8682 8.39593 15.9597 8.52656 16.0224C8.6572 16.0851 8.80081 16.1171 8.94614 16.1161H12.5886L10.885 22.3593C10.8292 22.5606 10.8441 22.7746 10.9273 22.9665C11.0105 23.1585 11.1571 23.3172 11.3434 23.4169C11.5297 23.5166 11.7447 23.5514 11.9536 23.5158C12.1625 23.4802 12.3531 23.3763 12.4945 23.2207L22.7538 12.1052C22.8759 11.9736 22.9569 11.81 22.987 11.6342C23.017 11.4584 22.9949 11.2778 22.9232 11.1141V11.1141ZM13.7087 19.1543L14.7158 15.4491C14.7539 15.3117 14.7592 15.1676 14.7315 15.0278C14.7037 14.888 14.6436 14.7564 14.5558 14.6433C14.4681 14.5312 14.3555 14.4403 14.2266 14.3777C14.0977 14.3151 13.956 14.2823 13.8122 14.282H10.198L12.2122 6.85313H16.8524L15.657 11.2438C15.6186 11.3837 15.6142 11.5305 15.6439 11.6725C15.6737 11.8144 15.7369 11.9475 15.8285 12.061C15.92 12.1745 16.0373 12.2653 16.171 12.3261C16.3047 12.3868 16.451 12.4159 16.5982 12.4109H19.9584L13.7087 19.1543Z"
        fill={iconColor}
      />
    </svg>
  );
};
