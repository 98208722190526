import React from 'react';

import { IconProps } from './types';

export const K6App = ({ iconColor }: IconProps) => {
  return (
    <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 29.9355H0L10.653 8.0318L17.0701 12.5791L25.4402 0L32 29.9355ZM20.2672 25.2045H20.3357C21.1277 25.2059 21.8893 24.9107 22.4599 24.3815C22.7523 24.1257 22.9849 23.813 23.1423 23.4639C23.2998 23.1148 23.3784 22.7376 23.373 22.357C23.3861 21.9903 23.3138 21.6256 23.1616 21.2893C23.0093 20.9531 22.7808 20.654 22.493 20.414C22.0078 19.9447 21.3539 19.6733 20.6668 19.6559H20.6143C20.5243 19.6555 20.4348 19.6673 20.3483 19.6911L22.0376 17.2706L20.6918 16.3651L20.0549 17.2706L18.4273 19.6669C18.1476 20.0641 17.9136 20.414 17.7687 20.6571C17.6183 20.9144 17.4889 21.1826 17.3817 21.4592C17.2598 21.7532 17.197 22.0668 17.1968 22.3834C17.193 22.7595 17.2713 23.1321 17.4267 23.4771C17.582 23.8221 17.811 24.1317 18.0985 24.3859C18.6617 24.9147 19.4171 25.2101 20.2033 25.2089L20.2672 25.2045ZM13.1128 22.5991L14.9779 25.1417H16.9719L14.7781 22.192L16.7266 19.5844L15.4333 18.7218L14.8626 19.448L13.1105 21.8289V17.0417L11.3641 15.6697V25.1406H13.1105V22.5968L13.1128 22.5991ZM20.2696 23.6168C19.929 23.6168 19.6024 23.4864 19.3615 23.2543C19.1207 23.0221 18.9854 22.7073 18.9854 22.379C18.9854 22.0507 19.1207 21.7359 19.3615 21.5038C19.6024 21.2716 19.929 21.1412 20.2696 21.1412H20.281C20.4501 21.1414 20.6174 21.1746 20.773 21.2386C20.9285 21.3027 21.069 21.3965 21.1861 21.5143C21.3093 21.6233 21.4073 21.756 21.474 21.9038C21.5405 22.0516 21.5743 22.2112 21.5731 22.3724C21.5689 22.7029 21.4299 23.0185 21.1861 23.251C20.9424 23.4834 20.6135 23.6141 20.2707 23.6146L20.2696 23.6168Z"
        fill={iconColor}
      />
    </svg>
  );
};
